@import url("https://fonts.cdnfonts.com/css/amazon-ember");
.a-icon {
  display: inline-block; }

.a-icon-premium.a-icon-small,
.a-icon-prime-jp.a-icon-small,
.a-icon-prime.a-icon-small,
.a-premium-logo.a-icon-small,
.a-prime-jp-logo.a-icon-small,
.a-prime-logo.a-icon-small {
  background-image: url("../images/prime_logo_smile_tile.png");
  width: 2.625rem;
  height: 1.75rem; }

.a-icon-check-mark-prime {
  background-image: url("../images/primeBadge.svg");
  width: 3.5rem;
  height: 1rem;
  background-repeat: no-repeat; }

.a-icon-returns,
.a-icon-free-shipping {
  width: 1.5rem;
  height: 1.5rem;
  background-repeat: no-repeat; }

.a-icon-no-returnable {
  width: 1rem;
  height: 1rem;
  background-repeat: no-repeat; }

.a-icon-returns {
  background-image: url("../images/amazon_icon_returns_box.svg"); }

.a-icon-free-shipping {
  background-image: url("../images/amazon_icon_delivery_FreeShipping.svg"); }

.a-icon-no-returnable {
  background-image: url("../images/not-returnable.svg"); }

.a-icon-manage-order {
  width: 1rem;
  height: 1rem;
  background-image: url("../images/amazon_icon_manage_order.svg"); }

.prime-order-link {
  height: 1rem;
  margin: 0.5rem 0 1.5rem; }
  .prime-order-link a {
    font-family: 'Amazon Ember', sans-serif;
    font-size: 1rem;
    line-height: 1rem;
    vertical-align: text-top;
    text-decoration: none;
    color: #0076D6; }

.bwp-prime-container {
  border-radius: 12px;
  width: 100%; }
  .bwp-prime-container.hidden.d-block {
    display: none !important; }
  .bwp-prime-container .prime-copy {
    margin-top: 0.03125rem; }

.bwp-container-checkout .bwp-prime-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .bwp-container-checkout .bwp-prime-container.hidden {
    display: none; }

.bwp-container-checkout .prime-logo {
  margin-right: 0.5rem; }

.bwp-container-checkout .prime-delivery-estimate {
  display: block;
  margin-top: -1px; }

.bwp-element-icons {
  font-size: 0.8125rem;
  padding: 0.5rem 1rem 0; }

.bwp-easy-returns,
.bwp-not-returnable {
  margin-left: 0.5rem; }

.bwp-cart-delivery,
.bwp-cart-returns {
  font-size: 0.8em;
  vertical-align: top; }

.bwp-icon-free-delivery {
  margin-left: 0.2rem; }

.prime-logo {
  margin: 0 1.5rem 0 0; }

.prime-logo img {
  width: 5rem; }

.hidden {
  display: none; }

.shimmer {
  color: grey;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
  background-repeat: no-repeat;
  -webkit-animation: shimmer 2.5s infinite;
          animation: shimmer 2.5s infinite;
  max-width: 100%;
  -webkit-filter: blur(9px);
          filter: blur(9px); }

@-webkit-keyframes shimmer {
  100% {
    -webkit-mask-position: left; } }

@keyframes shimmer {
  100% {
    -webkit-mask-position: left; } }

.btn-bwp {
  background-color: #1a98ff !important;
  border-color: #1a98ff !important;
  margin: 0 auto; }
  .btn-bwp > * {
    pointer-events: none; }

.btn-bwp:hover {
  background-color: #0683ea !important; }

.btn-bwp-round {
  border-radius: 1.5rem; }

.btn.btn-bwp-square-round {
  border-radius: 4px; }

.btn.buyWithPrimeElement {
  padding: 0.3em 1em; }

.bwp-text-black {
  color: #000; }

.bwp-text-white {
  color: #fff; }

.bwp-font-amazon_ember {
  font-family: 'Amazon Ember', sans-serif; }

.btn-add-new:focus {
  outline: 0;
  -webkit-transition: none;
  transition: none;
  -webkit-box-shadow: none;
          box-shadow: none; }

#footercontent {
  margin-top: 3rem; }

#bwp-signup .modal-header img {
  -webkit-filter: invert(28%) sepia(84%) saturate(1644%) hue-rotate(173deg) brightness(97%) contrast(101%);
          filter: invert(28%) sepia(84%) saturate(1644%) hue-rotate(173deg) brightness(97%) contrast(101%); }

#bwp-signup .bwp-signup-modal-line1 {
  margin-bottom: 0;
  font-weight: 600; }

#bwp-signup .modal-footer a.btn {
  border-radius: 1rem; }

#bwp-signup .modal-header,
#bwp-signup .modal-body,
#bwp-signup .modal-footer {
  border: 0; }

.prime-card-shipping-sign-in {
  padding: 1rem;
  border-radius: 0.8rem;
  font-family: 'Amazon Ember', sans-serif;
  background-color: #e9ecef;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  font-size: 0.8em;
  margin: 1rem 0; }
  .prime-card-shipping-sign-in i {
    vertical-align: middle; }
  .prime-card-shipping-sign-in p {
    margin-bottom: 0;
    margin-left: 20px; }
  .prime-card-shipping-sign-in a.sign-in-link {
    color: #000 !important;
    text-decoration: underline; }
  @media (max-width: 544px) {
    .prime-card-shipping-sign-in {
      max-width: 100%; } }

.buy-with-prime-prime-day-deal-badge {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 22px;
  padding: 0px 6px;
  background-color: #CC0C39;
  color: white;
  border-radius: 4px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .buy-with-prime-prime-day-deal-badge p {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    font-family: "Amazon Ember"; }

.buy-with-prime-prime-day-deal-badge-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 22px; }
  .buy-with-prime-prime-day-deal-badge-text p {
    color: #CC0C39;
    font-family: "Amazon Ember";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 8px; }

.buy-with-prime-prime-day-deal-badge-sm {
  height: 20px;
  padding: 0px 6px;
  font-size: 12px;
  background-color: #CC0C39;
  color: white;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.buy-with-prime-prime-day-deal-badge-text-sm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 20px;
  font-size: 14px;
  padding: 0; }
  .buy-with-prime-prime-day-deal-badge-text-sm p {
    padding: 0;
    color: #CC0C39;
    font-family: "Amazon Ember";
    font-style: normal;
    font-weight: 400;
    line-height: normal; }

.buy-with-prime-prime-day-deal-badge-text-inject {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 20px;
  font-size: 14px;
  padding: 0;
  padding-left: 8px; }
  .buy-with-prime-prime-day-deal-badge-text-inject p {
    padding: 0;
    color: #CC0C39;
    font-family: "Amazon Ember";
    font-style: normal;
    font-weight: 400;
    line-height: normal; }

.buy-with-prime-prime-day-deal-badge-text-mini-cart {
  padding-left: 0px;
  font-size: 12px; }

.buy-with-prime-prime-day-deal-badge-sm-container-card {
  height: 23px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row; }

.buy-with-prime-prime-day-deal-badge-sm-container {
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 10px;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }

.buy-with-prime-prime-day-deal-badge-mini-cart-container {
  min-height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-top: 5px; }

.bwp-card-prime-day-deal-badge-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

@media (max-width: 1200px) {
  .buy-with-prime-prime-day-deal-badge-text p {
    font-size: 15px; }
  .buy-with-prime-prime-day-deal-badge-sm-container {
    height: 55px; } }

@media (max-width: 992px) {
  .bwp-card-prime-day-deal-badge-container, .buy-with-prime-prime-day-deal-badge-sm-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .bwp-card-prime-day-deal-badge-container p, .bwp-card-prime-day-deal-badge-container .buy-with-prime-prime-day-deal-badge-text-sm, .bwp-card-prime-day-deal-badge-container .buy-with-prime-prime-day-deal-badge-text-inject, .buy-with-prime-prime-day-deal-badge-sm-container p, .buy-with-prime-prime-day-deal-badge-sm-container .buy-with-prime-prime-day-deal-badge-text-sm, .buy-with-prime-prime-day-deal-badge-sm-container .buy-with-prime-prime-day-deal-badge-text-inject {
      padding-left: 0; }
  .buy-with-prime-prime-day-deal-badge-sm-container {
    height: 60px; } }

@media (max-width: 715px) {
  .buy-with-prime-prime-day-deal-badge-sm-container {
    height: 75px; } }

@media (max-width: 543px) {
  .buy-with-prime-prime-day-deal-badge-sm-container {
    height: 40px; } }

@media (max-width: 430px) {
  .bwp-container-checkout .buy-with-prime-prime-day-deal-badge-text-sm {
    font-size: 11px; } }

@media (max-width: 380px) {
  .bwp-container-checkout .buy-with-prime-prime-day-deal-badge-text-sm {
    font-size: 10px; } }

@media (min-width: 544px) {
  .prices-add-to-cart-actions {
    padding: 0; }
    .prices-add-to-cart-actions .btn-primary {
      width: 100%; } }

@media (max-width: 543.98px) {
  .prices-add-to-cart-actions .bwp-button-row-pdp {
    margin: 0; }
    .prices-add-to-cart-actions .bwp-button-row-pdp .col-12 {
      padding: 0; }
      .prices-add-to-cart-actions .bwp-button-row-pdp .col-12 .btn-primary {
        width: 98% !important; } }

.shipping-method-list .form-check.start-lines {
  border: 1px solid black;
  border-radius: 100px 0 0 100px;
  border-right: 0;
  margin-bottom: 20px;
  padding: 12px 0 12px 40px; }
  .shipping-method-list .form-check.start-lines::before {
    content: "" !important; }
  .shipping-method-list .form-check.start-lines .form-check-input {
    margin-top: 0;
    margin-left: -1.5rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }

.shipping-method-list .shipping-method-pricing.end-lines {
  border: 1px solid black;
  border-radius: 0 100px 100px 0;
  border-left: 0;
  margin-bottom: 20px;
  padding: 12px 15px 12px 0; }
  .shipping-method-list .shipping-method-pricing.end-lines .shipping-cost {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    display: inline-block; }

.bwp-single-shipping .leading-lines.bwpbage .start-lines {
  border: 1px solid black;
  border-radius: 100px 0 0 100px;
  border-right: 0;
  margin-bottom: 20px;
  padding: 12px 0 12px 40px; }
  .bwp-single-shipping .leading-lines.bwpbage .start-lines::before {
    content: "";
    height: 16px;
    width: 16px;
    background: black;
    position: absolute;
    top: 50%;
    left: 15px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    border-radius: 100%; }
  .bwp-single-shipping .leading-lines.bwpbage .start-lines .shipping-method {
    margin-bottom: 0; }

.bwp-single-shipping .leading-lines.bwpbage .end-lines {
  border: 1px solid black;
  border-radius: 0 100px 100px 0;
  border-left: 0;
  margin-bottom: 20px;
  padding: 12px 15px 12px 0; }
  .bwp-single-shipping .leading-lines.bwpbage .end-lines .shipping-method-price {
    margin-bottom: 0;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }

.shipping-method-list .shipping-method-wrapper {
  width: 100%; }
  .shipping-method-list .shipping-method-wrapper .shipping-method-pricing.end-lines {
    border: 0;
    margin-bottom: 0;
    padding: 0; }
  .shipping-method-list .shipping-method-wrapper .custom-control-label {
    margin-top: 0;
    margin-bottom: 0; }

.bwpbage .shipping-method {
  margin-left: 10px; }
  .bwpbage .shipping-method .shipping-method-title .pt-2.pl-0.col-12 {
    padding-top: 0 !important; }
  .bwpbage .shipping-method .shipping-method-title .a-icon {
    vertical-align: middle; }
